import React, { useCallback, useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import AuthForm, { AuthStates, ResetProps } from './AuthForm';
import { getInfoFromUrl, QueriesProps } from '../../api/auth';
import { toast } from 'react-toastify';
import toastOptions from '../accessories/toast-options';
import PiLabsWhiteLogo from '../../assets/logo-white.png';
import createCognitoUser from './createCognitoUser';
import {
	fetchAuthCode,
	requestForgotPassword,
	requestResetPassword,
	requestSetNewPassword,
	signInWithCode,
} from '../../actions/AuthActions';
import { useDispatch, useSelector } from 'react-redux';
import IStore from '../../store/IStore';
import Footer from '@chaptesi/licenses/components/Footer';
import checkPasswordForErrors from '../../utils/checkPasswordForErrors';
import { isValidDomain } from '../../utils/url';

const theme = createMuiTheme({
	palette: {
		primary: { main: '#00baff' },
		secondary: { main: '#fff' },
	},
});

const useStyles = makeStyles((theme) => ({
	loginPage: {
		background: "url(../background.webp)",
		backgroundSize: 'cover',
		height: '100%',
		width: '100%',
	},
	loginContent: {
		maxWidth: '1200px',
		position: 'relative',
		margin: 'auto',
		display: 'flex',
		flexWrap: 'wrap',
		height: '100%',
		width: '100%',
		color: 'white',
		'& label': {
			color: 'white',
		},
		'& input': {
			color: 'white',
			border: 'none',
		},
		'& .Mui-focused': {
			color: 'inherit',
		},
		'& .Mui-error': {
			color: '#f44336',
		},
		'& .MuiInput-underline': {
			borderBottom: 'solid 2px white',
			'&::after': {
				borderBottom: 'solid 2px white',
			},
		},
	},
	pilabsWhiteLogo: {
		position: 'absolute',
		top: '1.5rem',
		left: '1.5rem',
	},
	message: {
		maxWidth: '30%',
		padding: '0 3rem',
		height: '5rem',
		'& h1': {
			lineHeight: 1.2,
		},
		[theme.breakpoints.down(769)]: {
			maxWidth: '50%',
		},
		[theme.breakpoints.down(689)]: {
			maxWidth: '95%',
		},
	},
	footer: {
		position: 'fixed',
		bottom: 0,
		left: 0,
		color: 'white',
		background: 'transparent',
		width: '100%',
	},
}));

const Authenticator = () => {
	const classes = useStyles();
	const [authState, setAuthState] = useState<AuthStates>(AuthStates.SIGN_IN);
	const [urlInfo, setUrlInfo] = useState<QueriesProps>({});
	const loading = useSelector<IStore, boolean>((state) => state.loading);
	const [session, setSession] = useState<string>('');
	const [needToAcceptTermsAndPolicy, setNeedToAcceptTermsAndPolicy] = useState(false);
	const dispatch = useDispatch();
	const [isAutoLogin, setIsAutoLogin] = useState(false);

	useEffect(() => {
		setUrlInfo(
			getInfoFromUrl(
				'redirect_uri',
				'redirectUri',
				'code',
				'forgotPassword',
				'username',
				'errorCode',
				'errorMessage',
				'email',
				'pass',
			),
		);
	}, []);

	const handleSignIn = useCallback(
		(username: string, password: string) => {
			return dispatch<any>(fetchAuthCode(username, password))
				.then((response: any) => {
					const code = response.data.code;
					const challengeName = response.data.challengeName;
					const needToAcceptTermsAndPolicy = response.data.needToAcceptTermsAndPolicy;
					let redirectUri = urlInfo.redirect_uri || urlInfo.redirectUri;
					setNeedToAcceptTermsAndPolicy(false);
					if (redirectUri && isValidDomain(redirectUri) && !challengeName) {
						if (redirectUri.indexOf('?') > 0) {
							redirectUri += `&code=${code}`;
						} else {
							redirectUri += `?code=${code}`;
						}

						window.open(redirectUri, '_self');
					} else if (code) {
						dispatch(signInWithCode(code));
						// @ts-ignore
						window.history.pushState('', 'home', '/');
					} else if (challengeName) {
						setSession(response.data.session);
						setNeedToAcceptTermsAndPolicy(needToAcceptTermsAndPolicy);
						setAuthState(AuthStates.SET_NEW_PASSWORD);
					}
					if (response.data.errorCode) {
						const message = response.data.errorMessage || 'An error has occurred';
						throw new Error(message);
					}
				})
				.catch((error: Error) => {
					toast.error(error.message, toastOptions);
					setIsAutoLogin(false);
				});
		},
		[dispatch, urlInfo],
	);

	useEffect(() => {
		if (urlInfo.forgotPassword) {
			setAuthState(AuthStates.RESET_PASSWORD);
		}
		if (urlInfo.email && urlInfo.pass) {
			setIsAutoLogin(true);
			handleSignIn(decodeURIComponent(urlInfo.email), decodeURIComponent(urlInfo.pass));
		}
		if (urlInfo.errorCode) {
			let message = 'An error has occurred';
			if (urlInfo.errorMessage) {
				message = urlInfo.errorMessage.replace(/%20/g, ' ');
			}
			toast.error(message, toastOptions);
			window.history.pushState('', 'login', '/login');
		}
	}, [urlInfo, handleSignIn]);

	const handleForgotPassword = (username: string) => {
		const redirectUri = urlInfo.redirect_uri || urlInfo.redirectUri || '';
		dispatch<any>(requestForgotPassword(username, redirectUri))
			.then(() => {
				toast.success('Please check you e-mail for code to reset your password!', toastOptions);
				setAuthState(AuthStates.RESET_PASSWORD);
			})
			.catch((error: Error) => {
				toast.error(error.message, toastOptions);
			});
	};

	const handleSetNewPassword = (username: string, password: string) => {
		const errorMessage = checkPasswordForErrors(password);

		if (errorMessage) {
			return Promise.reject(errorMessage);
		}

		const user = createCognitoUser(username, session);

		return dispatch<any>(requestSetNewPassword(user, password))
			.then(() => {
				toast.success('Your password has been successfully reset!', toastOptions);
				handleSignIn(username, password);
			})
			.catch((error: Error) => {
				toast.error(error.message, toastOptions);
			})
			.finally(() => {
				setIsAutoLogin(true);
				setAuthState(AuthStates.SIGN_IN);
			});
	};

	const handleResetPassword = ({ username, code, password }: ResetProps) => {
		const errorMessage = checkPasswordForErrors(password);

		if (errorMessage) {
			return Promise.reject(errorMessage);
		}

		return dispatch<any>(requestResetPassword(username, code, password))
			.then(() => {
				setAuthState(AuthStates.SIGN_IN);
				setIsAutoLogin(true);
				toast.success('Your password has been successfully reset!', toastOptions);
				handleSignIn(username, password);
			})
			.catch((error: Error) => {
				setAuthState(AuthStates.SIGN_IN);
				toast.error(error.message, toastOptions);
			})
			.finally(() => {
				const redirectUri = `${urlInfo.redirect_uri || urlInfo.redirectUri || ''}`;
				const url = redirectUri ? `/?redirect_uri=${redirectUri}` : '/';
				// @ts-ignore
				window.history.pushState('', 'home', url);
			});
	};

	const formProps = {
		signIn: {
			linkText: 'Forgot password',
			submitButtonText: 'Login',
			handleSignIn,
			handleStateChange: () => setAuthState(AuthStates.FORGOT_PASSWORD),
		},
		forgotPassword: {
			linkText: 'Login instead',
			submitButtonText: 'Request Reset',
			subtitleText: `Enter your username and request a reset.
			You will receive a password reset mail
			with a link to set a new password.`,
			handleForgotPassword,
			handleStateChange: () => setAuthState(AuthStates.SIGN_IN),
		},
		resetPassword: {
			linkText: 'Back to Login',
			submitButtonText: 'Submit',
			handleResetPassword,
			handleStateChange: () => setAuthState(AuthStates.SIGN_IN),
		},
		setNewPassword: {
			linkText: 'Back to Login',
			submitButtonText: 'Submit',
			handleSetNewPassword,
			handleStateChange: () => setAuthState(AuthStates.SIGN_IN),
		},
	};

	return (
		<div className={classes.loginPage}>
			<MuiThemeProvider theme={theme}>
				<div className={classes.loginContent}>
					<div className={classes.pilabsWhiteLogo}>
						<img src={PiLabsWhiteLogo} alt="PI Labs logo" />
					</div>
					<AuthForm
						{...formProps[authState]}
						isAutoLogin={isAutoLogin}
						urlInfo={urlInfo}
						loading={loading}
						authState={authState}
						needToAcceptTermsAndPolicy={needToAcceptTermsAndPolicy}
					/>					
				</div>
				<Footer className={classes.footer} />
			</MuiThemeProvider>
		</div>
	);
};

export default Authenticator;
